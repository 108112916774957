<template>
  <div class="container">
    <pre style="display: none">{{ pagination | pretty_json }}}</pre>
    <h1>Lista de Papéis</h1>
    <div class="card">
      <div class="card-body">
        <router-link
          :to="{
            name: 'acl_create'
          }"
          class="btn btn-outline-primary ml-auto link-edit"
          >Novo</router-link
        >
        <b-table striped hover :items="roles" :fields="fields">
          <template #cell(updated_at)="row">
            {{ row.item.updated_at | format_date }}
          </template>
          <template #cell(cpf)="row">
            {{ row.item.cpf | cpf }}
          </template>
          <template #cell(actions)="row">
            <Actions
              :item="row.item"
              :view_show="false"
              edit_to="acl_edit"
              @delete="delete_role"
            />
          </template>
        </b-table>
      </div>
    </div>
    <div class="overflow-auto mt-4">
      <b-pagination-nav
        v-model="page"
        :link-gen="linkGen"
        :number-of-pages="pagination.last_page"
        use-router
      ></b-pagination-nav>
    </div>
  </div>
</template>

<script>
import Actions from "@/components/Table/Actions.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { Actions },
  data() {
    return {
      page: 1
    };
  },
  computed: {
    ...mapState("acl", ["roles", "role", "pagination"]),
    fields() {
      return [
        { key: "id", label: "#" },
        { key: "name", label: "Nome" },
        { key: "updated_at", label: "Atualizado em" },
        { key: "actions", label: "#", class: "col-1" }
      ];
    }
  },
  methods: {
    ...mapActions("acl", ["ActionGetAll", "ActionDelete"]),
    async delete_role(role) {
      try {
        await this.ActionDelete(role.id);
        this.$notifications.addNotification({
          message: "Registro deletado com sucesso",
          type: "success"
        });
        await this.ActionGetAll({});
      } catch (error) {
        this.$notifications.addNotification({
          message: "Erro ao excluir registro",
          type: "danger"
        });
      }
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    }
  },
  async created() {
    if (this.$route.query.page > 1) {
      this.page = this.$route.query.page;
    }
    await this.ActionGetAll({ page: this.page });
  }
};
</script>

<style></style>
